<template>
	<div class="main-contents code">
		<div class="tit">업무코드 정보관리</div>
		<div class="search-box interval">
			<SelectComp list="CD_ID:업무코드,CD_ID_NM:업무코드명" :isAll="true" title="전체" v-model="srchFilter.searchOption" @change="placeholderTxt = $event.target.selectedOptions[0].label"/>
			<InputComp v-model="srchFilter.srchTxt" :placeholder="placeholderTxt" @keyup.enter="getBusinessCodeList('1')" />
			<nbsp/>
			<SelectComp class="wd01" list="Y:예,N:아니오" v-model="srchFilter.useYn" :isAll="true" title="사용여부" />
			<nbsp/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getBusinessCodeList('1')"/>
			<div class="sub_btn" @click="$router.push('MAN931M02')">
			+ 등록
		</div>
	</div>
	<!-- 업무코드 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
 				<colgroup>
					<col width="5%">
					<col width="30%">
					<col width="55%">
					<col width="10%">
				</colgroup> 
				<thead>
					<tr>
						<th>NO</th>
						<th>업무코드</th>
						<th>업무코드명</th>
						<th>사용여부</th>
					</tr>
				</thead>
				<tbody v-if="businessCodeList.length > 0">
					<!-- 최대 25건 조회 -->
					<tr v-for="(row, rowIdx) in businessCodeList" :key="rowIdx">
						<td class="score">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + rowIdx + 1}}</td>
						<td class="score">{{row.cdId}}</td>
						<td class="score btn_cursor" @click="modify(row.cdId)">{{row.cdIdNm}} &#9654;</td>
						<td class="score">
							<div v-if="row.useYn == 'Y'">예</div>
							<div v-if="row.useYn == 'N'">아니오</div>
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<!-- 관련내용 없을 경우 -->
					<tr>
						<td colspan="6" class="none">등록된 업무코드가 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
export default {
	data(){
		return{
			//검색조건
			srchFilter : {
				searchOption: '',
				srchTxt : '',
				pageIndex : 1,
				useYn : ''
			},
			//업무 코드 목록
			businessCodeList : [],

			//페이징
			pageInfo : {},

			placeholderTxt : '업무코드',
		}
	},

	components : {
		pagingComp
	},

	beforeMount(){

	},

	mounted() {
		var keys = Object.keys(this.srchFilter);
		for(var key in this.$route.params){
			if(keys.indexOf(key)>=0){
				this.srchFilter[key] = this.$route.params[key];
			}
		}

		this.getBusinessCodeList()
	},

	methods : {
		//업무코드 목록 조회
		getBusinessCodeList(div){
			var param = this.srchFilter;
			param.pageUnit = '25';
			param.pageSize = '10';
			if(div) param.pageIndex = 1;

			// console.log('getBusinessCodeList PARAM',param);

			this.$.httpPost('/api/main/adm/code/getBusinessCodeList', param)
				.then(res =>{
					// console.log('getBusinessCodeList RESULT',res);

					this.businessCodeList = res.data.businessCodeList;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
		},

		// 페이징 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getBusinessCodeList();
		},
		
		modify(cdId){
			// console.log('cdId',cdId);
			this.$router.push({name: 'MAN931M02', params: {cdId:cdId}});
		}
	}
}
</script>

<style>

</style>